window.App = {
  init: function() {
    var module;
    for (module in App) {
      if (typeof App[module].init === 'function') {
        App[module].init();
      }
    }
  },
  load: function() {
    var module;
    for (module in App) {
      if (typeof App[module].load === 'function') {
        App[module].load();
      }
    }
  }
};

App.slider = {
  init: function() {
    this.home = new Swiper(".main-swiper", {
      direction: "horizontal",
      loop: true,
      effect: 'fade',
      autoplay: 8000,
      paginationClickable: true,
      loopAdditionalSlides: 0,
      lazyLoadingInPrevNext: 1,
      lazyLoading: true,
      autoplayDisableOnInteraction: false,
      nextButton: ".main-swiper .swiper-button-next",
      prevButton: ".main-swiper .swiper-button-prev",
      preventClicks: false
    });
    this.gallery = new Swiper(".photo-swiper", {
      direction: "horizontal",
      effect: 'slide',
      spaceBetween: 10,
      lazyLoadingInPrevNext: 1,
      lazyLoading: true,
      nextButton: ".photo-swiper .button-next",
      prevButton: ".photo-swiper .button-prev"
    });
    this.thumbs = new Swiper(".photo-thumbs-swiper", {
      nextButton: ".photo-thumbs-swiper .button-next",
      prevButton: ".photo-thumbs-swiper .button-prev",
      spaceBetween: 0,
      centeredSlides: true,
      slidesPerView: 'auto',
      touchRatio: 0.2,
      slideToClickedSlide: true
    });
    this.gallery.params.control = this.thumbs;
    this.thumbs.params.control = this.gallery;
    this.sliders = new Swiper(".video-swiper", {
      direction: "horizontal",
      effect: 'slide',
      slidesPerView: 'auto',
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      nextButton: ".video-swiper .swiper-button-next",
      prevButton: ".video-swiper .swiper-button-prev"
    });
    $('.video-swiper .swiper-button-next, .video-swiper .swiper-button-prev').click(function() {
      return $('.youtube-video').each(function() {
        return this.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      });
    });
  }
};

App.contact = {
  init: function() {
    $('#contactForm').on('submit', function(e) {
      var $this;
      e.preventDefault();
      $this = $(this);
      $this.addClass('loading');
      $('[name="realname"]').val($('[name="nome"]').val());
      $('[name="e-mail"]').val($('[name="email"]').val());
      $.ajax({
        url: $this.attr('action'),
        type: 'POST',
        dataType: 'html',
        data: $this.serialize()
      }).done(function(data) {
        alert('Seu e-mail foi enviado com sucesso. Obrigado!');
        return $('#contactForm').find('input, textarea').prop('disabled', false).val('');
      });
      return $this.find('input, textarea').prop('disabled', true);
    });
  }
};

App.map = {
  $instance: null,
  m1: null,
  m2: null,
  init: function() {
    App.map.$instance = $('#google-maps .map').height(500).gmap3({
      map: {
        options: {
          center: [-23.507302, -46.671092],
          zoom: 15,
          mapTypeControl: false,
          navigationControl: true,
          scrollwheel: false,
          draggable: false,
          zoomControl: true,
          streetViewControl: true,
          zoomControlOptions: {
            style: google.maps.ZoomControlStyle.LARGE,
            position: google.maps.ControlPosition.RIGHT_TOP
          },
          panControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP
          }
        }
      },
      marker: {
        tag: "to",
        address: "Av. Casa Verde, 3369 - Bairro do Limão 02519-200 - São Paulo",
        callback: function(marker) {
          return App.map.m2 = marker;
        },
        events: {
          click: function(marker, event, context) {
            var content, infowindow, map;
            map = $(this).gmap3("get");
            infowindow = $(this).gmap3({
              get: {
                name: "infowindow"
              }
            });
            content = "<div class=\"info\">";
            content += "<h3>AutoChef</h3>";
            content += "<p>Av. Casa Verde, 3369 <br/> CEP 02519-200 - Bairro do Limão<br/> São Paulo - SP - Brasil ";
            content += "</div>";
            if (infowindow) {
              infowindow.open(map, marker);
              return infowindow.setContent(content);
            } else {
              return $(this).gmap3({
                infowindow: {
                  anchor: marker,
                  options: {
                    content: content
                  }
                }
              });
            }
          }
        }
      },
      directionsrenderer: {
        divId: "directions",
        options: {
          preserveViewport: true,
          markerOptions: {
            visible: false
          }
        }
      }
    });
    $('#getDirections').on('click', function(event) {
      event.preventDefault();
      $('#directions').toggleClass('opened');
      return $('#getDirections').toggleClass('opened');
    });
    return $("#address").geocomplete().bind("geocode:result", function(event, result) {
      return App.map.$instance.gmap3({
        clear: {
          name: "marker",
          tag: "from"
        },
        marker: {
          latLng: result.geometry.location,
          tag: "from",
          callback: function(marker) {
            App.map.m1 = marker;
            return App.map.updateDirections();
          }
        },
        map: {
          options: {
            center: result.geometry.location
          }
        }
      });
    });
  },
  updateDirections: function() {
    if (!(App.map.m1 && App.map.m2)) {
      return;
    }
    $('#getDirections').show();
    return App.map.$instance.gmap3({
      getroute: {
        options: {
          origin: App.map.m1.getPosition(),
          destination: App.map.m2.getPosition(),
          travelMode: google.maps.DirectionsTravelMode.DRIVING
        },
        callback: function(results) {
          var bounds;
          if (!results) {
            return;
          }
          App.map.$instance.gmap3({
            get: "directionsrenderer"
          }).setDirections(results);
          bounds = results.routes[0].bounds;
          App.map.$instance.gmap3({
            get: 'map'
          }).fitBounds(bounds);
          return App.map.$instance.gmap3({
            get: 'map'
          }).setCenter(bounds.getCenter());
        }
      }
    });
  }
};

App.scrollStick = {
  init: function() {
    App.scrollStick.make();
    return $(window).scroll(function() {
      return App.scrollStick.make();
    });
  },
  make: function() {
    if ((window.pageYOffset || window.scrollY) > 200) {
      return $('body').addClass('stick');
    } else {
      return $('body').removeClass('stick');
    }
  }
};

window.$window = $(window);

App.nav = {
  init: function() {
    $('a[href^="/"]').bind('click', function(e) {
      return $('body').removeClass('opened-menu');
    });
    return $(document).on('click', function(e) {
      var $target;
      if (($(e.target).closest('.menu-trigger').length)) {
        return $('body').toggleClass('opened-menu');
      }
      $target = $(e.target);
      if (($target.closest('.search-box').length)) {
        return;
      }
      if ($('body').hasClass('opened-menu') && !$target.closest('.main-nav').length) {
        return $('body').removeClass('opened-menu');
      }
    });
  }
};

if ($('body').hasClass('home')) {
  App.history = {
    init: function() {
      return $('a[href^="/"]').bind('click', function(e) {
        var State, regex;
        e.preventDefault();
        History.pushState(null, null, this.href);
        State = History.getState();
        if (State.hash.search(/(\/)$/) >= 0) {
          return $.scrollTo('#top', 'slow');
        } else if (State.hash.search(/(quem-somos|como-funciona|tela-de-orientacao|beneficios|diferenciais|contato)$/) >= 0) {
          regex = /(\#\.\/|\.\/|\/)/;
          return $.scrollTo(State.hash.replace(regex, '#'), 'slow', {
            offset: function() {
              return {
                top: ($('header').height()) * -1,
                left: 0
              };
            }
          });
        }
      });
    }
  };
  History.Adapter.bind(window, 'statechange', function(e) {
    var State;
    e.preventDefault();
    State = History.getState();
    ga('send', 'pageview', State.hash);
    if (State.url === "?search") {
      return search(data.params);
    }
  });
  History.Adapter.onDomLoad(function() {
    var State, hash;
    State = History.getState();
    if (State.hash !== '/') {
      hash = (State.hash.substr(-1) === '/' ? State.hash.substr(0, State.hash.length - 1) : State.hash).replace('/', '#');
      $.scrollTo(hash, 'slow', {
        offset: function() {
          return {
            top: ($('#brand').height() + 20) * -1,
            left: 0
          };
        }
      });
    }
  });
  $('[href="/"]').click(function() {
    return $.scrollTo('#top', 'slow');
  });
}

jQuery(function() {
  App.init();
});
